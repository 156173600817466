.App-container {

}

.Top-container {
    position: relative;
}
a { color: inherit; }

/*MIDDLEBOX*/
.mid-box {
    position: absolute;
    top: 0;
    text-align: center;
    font-size: 18px;
    color: white;
    width: 50%;
    margin: 20% 25%;
    font-family: "Avenir", Avenir, serif;

}

.mid-box > .message {
    font-size: 3vw;
    line-height: 40%;
}

#name {
    color: #63ff82;
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    margin: 0 auto;
    font-size: 3vw;
}


.links > p {
    cursor: pointer;
}

.links > p:hover {
    font-weight: bold;
}

/*MENU*/

.menubar {
    color: white;
    position: page;
    width: 100%;
    height: 100%;
    display:flex;
}

.button {
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
}

.button :hover{
    background: #02020c;
}

/* MediaBlockList */
.media-container {
    width:100%
}

.media-list {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}


/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 450px) {

    .mid-box {
        position: absolute;
        text-align: center;
        color: white;
        height: 25%;
        width: 90%;
        margin: 20% 5%;
        font-family: "Avenir", Avenir, serif;

    }
    .mid-box > .message {
        font-size: 5vw;
        line-height: 40%;
    }

    .links {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 50%;
        margin: 0 auto;
        font-size: 6vw;
    }

}